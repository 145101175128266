<template>
  <header class="desktop">
    <MediaRail />
    <LinkRail />
    <SearchRail />
    <NavigationRail />
  </header>
</template>

<script setup>
/* istanbul ignore file */
import { computed } from 'vue';
import { useStore } from 'vuex';

import MediaRail from '../header-responsive/MediaRail.bcom';
import LinkRail from '../link-rail/LinkRail.bcom';
import SearchRail from './SearchRail.bcom';
import NavigationRail from './NavigationRail.common';
import { useRegistry } from '../../composables/useRegistry';

import {
  onUserInit, onKsFlagsReady, onAllPassedEventsReady, onKsFlagsFetched,
} from '../../events';
import { FETCH_PREFERRED_STORE } from '../../types/actions';
import { storeLoyaltyStatusForLegacy } from '../../composables/useLoyalty';

const store = useStore();
const { isMcom } = store.state.envProps;
const isSignedIn = computed(() => !!store.state.navUser.data?.isSignedIn);
const { fetchRegistries } = useRegistry({ checkUpdates: true });

const fetchPreferredStore = async () => {
  await store.dispatch(`navPreferredStore/${FETCH_PREFERRED_STORE}`);
};

const fetchLoyalPoints = () => {
  if (isSignedIn.value) {
    store.dispatch('navLoyaltyStore/fetchRewardsData');
  } else {
    storeLoyaltyStatusForLegacy({}, isMcom);
  }
};

onUserInit(fetchPreferredStore);
onAllPassedEventsReady(
  [onKsFlagsFetched, onKsFlagsReady, onUserInit],
  () => {
    fetchRegistries();
    fetchLoyalPoints();
  },
);
</script>
